/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo } from 'react'

import { Button, Text } from '@grupoboticario/flora-react'
import { CrossIcon } from '@grupoboticario/flora-react-icons'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { callAPIWithPromise, ResponseType } from 'support/components/api/CallApiWithPromisse'
import { getAPI, postAPI } from 'support/components/api/PainelErpApiClient'
import { useApiCall } from 'support/components/api/useCallApi'
import NativeApp from 'support/components/nativeapp/NativeApp'
import { logCORE } from 'support/util/Logger'

import { BannerTokens, useStyles } from './styles'

type BannerVisibilidade = 'jornada' | 'diaria' | 'semanal' | 'nunca'
type BannerButtonTipo = 'unsubscribe' | 'redirect' | 'mobile-event'
interface BannerButton {
  id_botao: number
  texto: string
  tipo: BannerButtonTipo
  acao: string
  outro: string
  preferencia?: BannerVisibilidade
  cor?: string
}

interface BannerProps {
  id: string
  titulo: string
  descricao: string
  imagem?: {
    mobile: string
    desktop: string
    tablet: string
  }
  cor: string
  botao: BannerButton[]
  visibilidade: BannerVisibilidade
}

export interface PromotionProps {
  modal?: BannerProps
  header?: BannerProps
  'banner-bottom'?: BannerProps
  'banner-top'?: BannerProps
}

const Banner: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(true)
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { data, error, callApi } = useApiCall<ResponseType<PromotionProps>>(getAPI)
  const { error: preferenceError, callApi: setPreference } = useApiCall<ResponseType<PromotionProps>>(postAPI)

  const classes = useStyles()
  const dataLayer = useMemo(() => window.dataLayer || [], []) as Record<string, any>
  const key = useMemo(
    () =>
      data?.data
        ? Object.keys(data?.data as object)
            .filter((key) => data?.data[key as keyof PromotionProps] !== null)
            .join('')
        : '',
    [data]
  ) as keyof PromotionProps

  const { titulo, descricao, imagem, botao = [], id: bannerId } = data?.data[key] || {}

  const redirectTo = (url: string) => {
    window.location.replace(url)
  }

  const closeBanner = (visibilidade: string) => {
    setPreference({ url: `erp/campanha/${bannerId}/preferencias`, data: { visibilidade }, requerAutorizacao: true }).finally(() => setIsVisible(false))
  }

  useEffect(() => {
    callApi({ url: 'erp/campanha', requerAutorizacao: true })
  }, [callApi])

  useEffect(() => {
    if (key && data?.data) {
      dataLayer.push({
        event: 'view_promotion',
        ecommerce: {
          items: [
            {
              promotion_id: String(data.data[key]?.id),
              promotion_name: String(data.data[key]?.titulo),
              creative_slot: String(data.data[key]?.descricao)
            }
          ]
        }
      })
    }
  }, [data, dataLayer, key])

  if (error || preferenceError) {
    logCORE(error || preferenceError)
  }

  const makeButtonAction = (tipo: BannerButtonTipo, acao: string) => {
    switch (tipo) {
      case 'unsubscribe':
        closeBanner(acao)
        break
      case 'redirect':
        setIsVisible(false)
        redirectTo(acao)
        break
      case 'mobile-event':
        setIsVisible(false)
        NativeApp.executeNativeMethod({
          method: acao
        })
        break
      default:
        break
    }
  }

  const onClick = ({ id_botao: id, tipo, texto, acao, preferencia: visibilidade }: BannerButton) => {
    dataLayer.push({
      event: 'select_promotion',
      ecommerce: {
        items: [
          {
            promotion_id: String(data?.data[key]?.id),
            promotion_name: String(data?.data[key]?.titulo),
            creative_slot: String(data?.data[key]?.descricao),
            cd_component_label: `click:${texto.toLowerCase()}`
          }
        ]
      }
    })
    callAPIWithPromise(postAPI, {
      url: `erp/campanha/${bannerId}/monitoramento`,
      data: {
        id_botao: id,
        texto_botao: texto
      },
      sendErroToGenericSnackbar: false,
      requerAutorizacao: true
    }).then(() => {
      if (visibilidade !== undefined) {
        setPreference({ url: `erp/campanha/${bannerId}/preferencias`, data: { visibilidade }, requerAutorizacao: true }).finally(() => makeButtonAction(tipo, acao))
      } else {
        makeButtonAction(tipo, acao)
      }
    })
  }

  const bannerColors = useMemo(() => (data?.data ? JSON.parse(String(data?.data?.[key]?.cor)) : null), [data?.data, key])

  const formatCloseButtonColor = useCallback(() => {
    if (bannerColors?.background === BannerTokens.backgroundColor.roxo) {
      return BannerTokens.backgroundColor.branco
    }
    return BannerTokens.backgroundColor.azul
  }, [bannerColors])

  const shouldRender = isVisible && titulo && descricao && imagem && botao.length

  if (!shouldRender) {
    return null
  }

  return (
    <div style={{ backgroundColor: bannerColors?.background }} className={classes.banner}>
      <div className={classes.content}>
        <div className={classes.title}>
          <Text style={{ color: bannerColors?.texto }} weight="heavy">
            {titulo}
          </Text>
        </div>
        <div className={classes.description}>
          <Text style={{ color: bannerColors?.texto }}>{descricao}</Text>
        </div>
        <div className={classes.button}>
          {botao.map((btn, i) => (
            <Button
              style={{
                backgroundColor: JSON.parse(String(btn.cor))?.background,
                color: JSON.parse(String(btn.cor))?.texto,
                boxShadow: `inset 0 0 0 1px ${JSON.parse(String(btn.cor))?.borda}`,
                borderColor: JSON.parse(String(btn.cor))?.borda
              }}
              key={btn.texto}
              variant={i === 0 ? 'ghost' : 'standard'}
              onClick={() => onClick(btn)}
            >
              {btn.texto}
            </Button>
          ))}
        </div>
      </div>
      <div className={classes.wrapperImageAndButton}>
        {imagem?.desktop && imagem?.mobile && (
          <div className={classes.containerImg}>
            <img src={isMdUp ? imagem.desktop : imagem.mobile} />
          </div>
        )}
        <div className={classes.wrapperCloseButton}>
          <Button
            style={{ color: formatCloseButtonColor() }}
            variant="ghost"
            size="small"
            className={classes.closeButton}
            onClick={() => {
              dataLayer.push({
                event: 'select_promotion',
                ecommerce: {
                  items: [
                    {
                      promotion_id: String(data?.data[key]?.id),
                      promotion_name: String(data?.data[key]?.titulo),
                      creative_slot: String(data?.data[key]?.descricao),
                      cd_component_label: 'click:fechar'
                    }
                  ]
                }
              })
              closeBanner(data?.data[key]?.visibilidade as string)
            }}
            data-testid="closeButton"
          >
            <CrossIcon size="small" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Banner

type JourneyType = 'padrao' | 'pagamento' | 'agendamento' | 'lembrete' | 'login'

export const setBannerJourney = (journey: JourneyType, headers: Record<string, string> = {}) => {
  try {
    callAPIWithPromise(postAPI, { url: 'erp/colaboradores/evento', data: { evento: 'fim-jornada', jornada: journey }, requerAutorizacao: true, headers })
  } catch (error) {
    logCORE(error)
  }
}
